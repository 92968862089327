body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
  url("./fonts/Roboto-Regular.ttf") format("truetype");
}

.loaderTitle{
  font-family: "Roboto";
  font-size: 2.25rem;
  font-weight: 700;
}

.topHeader{
  position: absolute;
  right: 0;
  left: 0;
  height:  70px;
  background: #FFFFFF;
  box-shadow: 0px 1px 20px 1px rgba(162, 162, 162, 0.25);
}

.topContainerMargin{
  margin-top: 70px;
}

.chooseRoomsOptions{
  max-height: 50vh;
}

.headerTitle{
  font-family: "Roboto";
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 21px;
}

.welcomeTitle{
  font-family: "Roboto";
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 42px;
}

.welcomePropertyId{
  font-family: "Roboto";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 24px;
}

.chooseText{
  font-family: "Roboto";
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 21px;
  color: #5E5C5C;
}

.otherRoomText{
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 700;
  line-height: 21px;
}

.backText{
  font-family: "Roboto";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16px;
  color: #A8A8A8;
}

.roomName{
  font-family: "Roboto";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 24px;
  color: #4C9FB9;
}

.roomPicText{
  font-family: "Roboto";
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 21px;
}

.roomCheckText{
  font-family: "Roboto";
  font-size: 2rem;
  font-weight: 700;
  line-height: 42px;
}

.maxLengthText{
  font-family: "Roboto";
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 16px;
  color: #5E5C5C;
}

.loaderTitle, .headerTitle, .welcomeTitle, .welcomePropertyId, .otherRoomText, .roomCheckText{
  color: #292D52;
}

.roundInput{
  border-radius: 25px;
  border: 2px solid #DEDDDD;
}

.animatedCamera{
  max-height: 35px;
  border: 1px red solid;
  border-radius: 50%;
  padding: 1px;
}

.welcomeInfoText{
  font-family: "Roboto";
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 22px;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%234C9FB9%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
}

.form-check-input:checked {
  background-color: white;
  border-color: #4C9FB9;
}

.form-check-input:focus{
  box-shadow: none;
}

.form-check-label{
  /*color: #4C9FB9;*/
}

input:focus-visible, textarea:focus-visible {
  outline-color: #4C9FB9;
}

.webcam-img{
  display: flex;
  align-items: center;
  justify-content: center;
}